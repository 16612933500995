@font-face {
  font-family: 'Basier Circle Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Basier Circle Regular'), url('./assets/fonts/BasierCircle-Regular.woff') format('woff');
  }
  
  @font-face {
  font-family: 'Basier Square Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Basier Square Regular'), url('./assets/fonts/BasierSquare-Regular.woff') format('woff');
  }

  @import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

body {
  /* font-family: 'Basier Circle Regular'; */
  font-family: "DM Sans", sans-serif;
}
.logo{
  font-family: "Outfit", sans-serif;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 1s ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 1s ease-in;
}

.bg {
  background-image: url('./assets/bg.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 115vh;
  width: 100%;
  overflow: hidden;
}

.cardBG {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('./assets/blue-photo.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
}


